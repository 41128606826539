<template>
  <div class="wrapper">
    <Header />

    <main class="tech">
      <form
        class="form form--login"
        @submit.prevent="handleSubmit"
        autocomplete="off"
      >
        <h1 class="tech__heading">Вход</h1>

        <div
          v-if="error"
          class="form__error"
        >{{ error }}</div>

        <FormRow>
          <Input
            name="username"
            v-model="username"
            @blur="$v.username.$touch"
            :error="$v.username.$error"
            required
          >Логин</Input>
        </FormRow>
        <FormRow>
          <Input
            name="password"
            type="password"
            v-model="password"
            @blur="$v.password.$touch"
            :error="$v.password.$error"
            required
          >Пароль</Input>
        </FormRow>
        <FormRow>
          <Button
            type="submit"
            class="form__submit"
          >Войти</Button>
        </FormRow>
      </form>
    </main>

    <Footer />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapActions } from 'vuex';

import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import Button from '@/components/Button.vue';
import FormRow from '@/components/FormRow.vue';
import Input from '@/components/Input.vue';

export default {
  name: 'Login',
  metaInfo: {
    title: 'Вход',
  },
  setup() {
    return {
      $v: useVuelidate(),
    };
  },
  data() {
    return {
      username: '',
      password: '',
      error: null,
    };
  },
  validations() {
    return {
      username: {
        required,
      },
      password: {
        required,
      },
    };
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        await this.loginUser({
          username: this.username,
          password: this.password,
        });

        this.$router.push('/map').catch(() => {});
      } catch (e) {
        this.error = e.message;
      }
    },
    ...mapActions(['loginUser']),
  },
  components: {
    Footer,
    Header,
    Button,
    Input,
    FormRow,
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tech {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tech__picture {
  width: 1em;
  height: 0.47em;
  font-size: 18rem;

  @media (min-width: 768px) {
    font-size: 24rem;
  }
}

.tech__heading {
  margin-top: 0;
  margin-bottom: 1.5rem;

  font-size: var(--fs-h3);
  font-weight: bold;
  text-align: center;

  @media (min-width: 768px) {
    font-size: var(--fs-h2);
  }
}

.form {
  padding: 1.5rem 2rem 1.5rem;
  border: 1px solid var(--color-gray-10);
  border-radius: 1rem;
}

.form__submit {
  margin-left: 3rem;
}

.form__error {
  margin-bottom: 2rem;
  color: var(--color-danger-20);
  text-align: center;
}
</style>

<style lang="scss">
.form--login .form__row {
  width: 20rem;
  margin-left: -3rem;
}
</style>
